/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { Spin } from 'antd';
import {
  API, Auth, DataStore, Hub,
} from 'aws-amplify';
import { navigate } from 'gatsby';
import React, { useEffect, useState, useMemo } from 'react';
import { useLocation } from '@reach/router';
import dayjs from 'dayjs';
import { CustomConfig } from '../../backendConfigProvider';
import { Role, User } from '../../models';
import { getPendingInvitations } from '../../services/InvitationService';
import * as styles from '../../styles/Workspaces.module.scss';
import { EnterpriseDetails } from '../../types/enterpriseInfo';
import { InviteDetails } from '../../types/inviteDetails';
import { fetchEnterpriseLogo } from '../Admin/Services/CompanyProfileService';
import { fetchUserBySub, fetchUsersBySub } from '../DataStore/UserService';
import { UserType, Workspace } from './AccountSwitcher';
import PendingInvitations from './PendingInvitations';
import { getCurrentUser, logout } from './Services/AuthService';
import { getUserCountry, isBDMUser, postEvent } from '../../services/utils';
import { getEnterpriseProfile } from '../GraphQL/Services';
import changeSync from '../../services/DataStoreConfigure';

export type MapOfInvitedEnterpriseList = {
  [key: string]: EnterpriseDetails
}

const WorkspacesView: React.FC = () => {
  const [isLoading, setIsLoading] = useState({
    loading: true,
    loadingText: 'Setting up your workspaces...',
  });
  const [workspaces, setWorkSpaces] = useState<Workspace[]>([] as Workspace[]);
  const [
    allPendingInvitations,
    setAllPendingInvitations,
  ] = useState<InviteDetails[]>([] as InviteDetails[]);
  const [
    mapOfInvitedEnterpriseDetails,
    setMapOfInvitedEnterpriseDetails,
  ] = useState<any>(null);
  const [currentAuthUser, setCurrentAuthUser] = useState<any>(null);
  const [userCountry, setUserCountry] = useState('');
  const [identifyEventTrigger, setIdentifyEventTrigger] = useState({
    isDataStoreReady: false,
    isUserAttributesUpdated: false,
    isNewWorkspace: false,
    workspaceInfo: {
      subscriptionStatus: '',
      plan: '',
      licenseCount: 1,
    },
  });

  const location: any = useLocation();
  const throughSignupFlow = location?.state?.throughSignupFlow;
  const inviteeSignupFlow = location?.state?.inviteeSignupFlow;

  const pageValidation = async (pendingInvitations:InviteDetails[], currentUser: any) => {
    const sub = currentUser?.attributes?.sub;
    const allUserProfiles = await fetchUsersBySub(sub);
    const targetRole = Role.OWNER;
    const ownerProfiles = allUserProfiles?.filter((
      profile:User,
    ) => profile?.roles?.length && profile?.roles?.includes(targetRole));
    if (allUserProfiles?.length === 1) {
      if (ownerProfiles?.length > 0) {
        if (!pendingInvitations?.length) {
          navigate('/');
        }
      }
    }
  };

  const fetchInvitationDetails = async (currentUser: any) => {
    try {
      const mapOfInvitedEnterprise: any = new Map();
      const pendingInvitations = await getPendingInvitations(currentUser);
      await pageValidation(pendingInvitations, currentUser);
      if (pendingInvitations?.length) {
        for (const invitation of pendingInvitations) {
          const enterpriseDetails = await getEnterpriseProfile(invitation?.inviterEnterpriseID);
          const url = await fetchEnterpriseLogo(enterpriseDetails) || '';
          const enterpriseInfoWithLogo = { logo: url, ...enterpriseDetails };
          mapOfInvitedEnterprise.set(invitation?.inviterEnterpriseID, enterpriseInfoWithLogo);
        }
      }
      setMapOfInvitedEnterpriseDetails(mapOfInvitedEnterprise);
      setAllPendingInvitations(pendingInvitations);
    } catch (error) {
      setMapOfInvitedEnterpriseDetails(null);
      setAllPendingInvitations([]);
    }
  };

  const fetchUserDetails = async (currentUser: any) => {
    try {
      const sub = currentUser?.attributes?.sub;
      const allUserProfiles = await fetchUsersBySub(sub);
      const res = [];
      for (let i = 0; i < allUserProfiles?.length; i += 1) {
        const currData = allUserProfiles[i];
        const enterpriseDetails = await getEnterpriseProfile(currData?.enterpriseID);
        const url = await fetchEnterpriseLogo(enterpriseDetails) || '';
        if ([Role.OWNER, Role.MANAGER, Role.ADMIN].includes(currData?.roles[0])) {
          res.push({
            ...currData,
            enterpriseProfile: {
              ...enterpriseDetails,
              logo: url,
            },
            roles: [
              ...currData.roles,
              'LEARNER',
            ],
          });
        } else {
          res.push({
            ...currData,
            enterpriseProfile: {
              ...enterpriseDetails,
              logo: url,
            },
            roles: ['LEARNER'],
          });
        }
      }

      setWorkSpaces(res);
    } catch (error) {
      setWorkSpaces([]);
    }
  };

  const triggerEvent = async (owner:User) => {
    try {
      const userId = owner?.id;
      const enterpriseId = owner?.enterpriseID;
      const companyProfileInfo = await getEnterpriseProfile(enterpriseId);
      const enterpriseName = companyProfileInfo?.name;
      const payload = {
        userId,
        enterpriseId,
        originalTimestamp: dayjs().toISOString(),
        sentAt: dayjs().toISOString(),
      };
      postEvent({
        ...payload,
        event: 'Signed Up',
        properties: {
          type: 'OWNER',
          accountName: enterpriseName,
        },
      });
      postEvent({
        ...payload,
        event: 'Account Created',
        properties: {
          accountName: enterpriseName,
        },
      });
      postEvent({
        ...payload,
        event: 'Account Added User',
        properties: {
          role: owner?.roles?.[0],
          accountName: enterpriseName,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const triggerIdentifyEvent = async () => {
    const currentUser = await getCurrentUser();
    const sub = currentUser?.attributes?.sub;
    const id = currentUser?.attributes?.['custom:active_user'];
    const userRes = await fetchUserBySub(sub, id);
    const phone = userRes?.phoneNumber;
    const title = userRes?.designation;
    const eventPayload = {
      userId: userRes?.id,
      enterpriseId: userRes?.enterpriseID,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      traits: {
        email: userRes?.email,
        firstName: userRes?.firstName,
        lastName: userRes?.lastName,
        name: userRes?.name,
        roles: userRes?.roles,
        ...(phone && { phone }),
        ...(title && { title }),
        createdAt: userRes?.createdAt,
        lastActive: dayjs().toISOString(),
        exclude: isBDMUser(userRes?.email),
      },
    };
    if (identifyEventTrigger.isNewWorkspace) {
      eventPayload.traits.enterpriseSubStatus = identifyEventTrigger
        .workspaceInfo.subscriptionStatus;
      eventPayload.traits.enterprisePlan = identifyEventTrigger.workspaceInfo.plan;
      eventPayload.traits.enterpriseLicenseCount = identifyEventTrigger.workspaceInfo.licenseCount;
    }
    postEvent(eventPayload, '/identify');
    window.dataLayer.push({
      event: 'identify',
      userId: `${CustomConfig.backendEnv}:${userRes?.id}`,
    }, 20);
  };

  const updateUserAttributes = async (workspaceId: string, role: Role[] | 'LEARNER', owner:User | null = null) => {
    const signedInUser = await getCurrentUser();
    const sub = signedInUser?.attributes?.sub;
    const payload = {
      'custom:active_user': workspaceId,
    };
    try {
      await Auth.updateUserAttributes(signedInUser, payload);
      setIdentifyEventTrigger((prevState) => ({ ...prevState, isUserAttributesUpdated: true }));

      const currentSession = await Auth.currentSession() as any;
      signedInUser.refreshSession(currentSession?.refreshToken, async () => {
        await DataStore.stop();
        await changeSync(sub);
        if (owner) {
          triggerEvent(owner);
        }
        Hub.listen('datastore', async (hubData) => {
          const { event, data } = hubData.payload;
          if (event === 'ready') {
            console.log('DS listener', data);
            setIdentifyEventTrigger((prevState) => ({ ...prevState, isDataStoreReady: true }));

            if (role === 'LEARNER') {
              navigate('/learner/');
            } else {
              navigate('/admin/overview/');
            }
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAccounts = async (workspace: Workspace, role: Role[] | 'LEARNER') => {
    setIsLoading({ loading: true, loadingText: 'Loading...' });
    await updateUserAttributes(workspace?.id, role);
  };

  const createWorkspaceHandler = async () => {
    setIsLoading({ loadingText: 'Creating your workspace', loading: true });

    const apiName = `enterpriseappconfigureuser-${CustomConfig.backendEnv}`;
    const path = '/configure-user';
    const configureApi = await API.post(apiName, path, {
      body: {
        sub: currentAuthUser?.attributes?.sub,
        location: userCountry,
      },
    });
    const user = configureApi?.user;
    const subscriptionStatus = configureApi.subscription?.status.toUpperCase();
    const plan = configureApi.subscription?.subscription_items.filter((item:any) => item.item_type === 'plan')[0].item_price_id;
    setIdentifyEventTrigger((prevState) => ({
      ...prevState,
      isNewWorkspace: true,
      workspaceInfo: {
        subscriptionStatus,
        plan,
        licenseCount: 1,
      },
    }));
    updateUserAttributes(user?.id, user?.roles[0], user);
  };

  const fetchUserCountry = async () => {
    const countryCode = await getUserCountry();
    setUserCountry(countryCode);
  };

  const loadDependencies = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      const sub = currentUser?.attributes.sub;
      await changeSync(sub);
      setCurrentAuthUser(currentUser);
      fetchUserCountry();
      await fetchInvitationDetails(currentUser);
      await fetchUserDetails(currentUser);
      setIsLoading((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      logout(false);
    }
  };

  useEffect(() => {
    loadDependencies();
  }, []);

  useEffect(() => {
    if (identifyEventTrigger.isDataStoreReady && identifyEventTrigger.isUserAttributesUpdated) {
      triggerIdentifyEvent();
    }
  }, [identifyEventTrigger.isDataStoreReady, identifyEventTrigger.isUserAttributesUpdated]);

  const hideCreateWorkspaceSection = useMemo(() => {
    if (CustomConfig?.selfServeMode === 'true') {
      if (inviteeSignupFlow) {
        return true;
      }

      if (workspaces?.length > 0) {
        const targetRole = Role.OWNER;
        const ownerProfiles = workspaces.filter((
          data:User,
        ) => data?.roles?.length && data?.roles.includes(targetRole));
        if (!ownerProfiles?.length && throughSignupFlow) {
          return false;
        }
      } else if (!workspaces?.length && (!allPendingInvitations?.length || throughSignupFlow)) {
        return false;
      }

      return true;
    }
    return true;
  }, [workspaces, inviteeSignupFlow, allPendingInvitations, throughSignupFlow, CustomConfig]);

  return (
    <>
      {
        isLoading.loading ? <Spin tip={isLoading.loadingText} style={{ height: '70vh', width: '100vw' }} />
          : (
            <div className={styles.workspaceWrapper}>
              <div className={styles.cardContainer}>
                <h2 className={styles.cardHeading}>Pending Invitations</h2>
                <div className={styles.yourWorkspaces}>
                  <div className={styles.workspace}>
                    <p className={styles.emailText}>
                      Invitations for
                      {' '}
                      <strong>{currentAuthUser?.attributes?.email}</strong>
                    </p>
                  </div>
                  { allPendingInvitations?.length && mapOfInvitedEnterpriseDetails
                    ? allPendingInvitations.map((invitation) => {
                      const enterpriseDetails = mapOfInvitedEnterpriseDetails.get(
                        invitation?.inviterEnterpriseID,
                      );
                      const { logo } = enterpriseDetails;

                      return (
                        <PendingInvitations
                          key={invitation.id}
                          invitation={invitation}
                          logo={logo}
                          enterpriseDetails={enterpriseDetails}
                          updateUserAttributes={updateUserAttributes}
                          setIsLoading={setIsLoading}
                          currentAuthUser={currentAuthUser}
                          isFirstProfile={!workspaces?.length}
                        />
                      );
                    })
                    : (
                      <div className={styles.workspace}>
                        <h2>No pending invitations</h2>
                      </div>
                    )}
                </div>
              </div>
              {hideCreateWorkspaceSection
                ? null
                : (
                  <div className={styles.createWorkspace}>
                    <h2 className={styles.text}>
                      Want to create workspace for your own enterprise?
                    </h2>
                    <button className={styles.createWorkspaceCta} type="button" onClick={createWorkspaceHandler}>Create Workspace</button>
                  </div>
                )}
              {
                  !inviteeSignupFlow && workspaces?.length > 0
                  && (
                  <div className={styles.cardContainer}>
                    <h2 className={styles.cardHeading}>Your workspaces</h2>
                    <div className={styles.yourWorkspaces}>
                      <div className={styles.workspace}>
                        <p className={styles.emailText}>
                          Workspaces for
                          {' '}
                          <strong>{currentAuthUser?.attributes?.email}</strong>
                        </p>
                      </div>
                      {
                          workspaces.map((workspace: Workspace) => {
                            const logo = workspace?.enterpriseProfile?.logo;
                            return (
                              <div key={workspace.id} className={styles.workspace}>
                                {logo ? <img className={styles.enterpriseLogo} src={logo} alt="logo" /> : <div className={styles.fallbackIcon} />}
                                <h2>{workspace.enterpriseProfile.name}</h2>
                                {
                              workspace?.roles?.map((role: any, i: number, roles) => {
                                const userType = role === 'LEARNER' ? UserType.LEARNER : UserType.ADMIN;
                                const length = roles?.length;
                                return (

                                  <button
                                    className={`${styles.toggleCta} ${length === 1 ? styles.singleCtaMargin : ''}`}
                                    key={role}
                                    type="button"
                                    onClick={() => toggleAccounts(workspace, role)}
                                  >
                                    {userType}
                                  </button>
                                );
                              })
                            }
                              </div>
                            );
                          })
                    }
                    </div>
                  </div>
                  )

              }
            </div>
          )
      }
    </>
  );
};

export default WorkspacesView;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../../styles/Footer.module.scss';

const currentYear = new Date().getFullYear();

const Footer: React.FC = () => (
  <footer className={styles.footerSection}>
    <div className={styles.footer}>
      <div className={styles.content}>
        <p>
          Copyright ©
          {' '}
          {currentYear}
          {' '}
          Upskillist Group Ltd
        </p>
        <div className={styles.socialIconsContainer}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/upskillist/"
          >
            <StaticImage
              placeholder="blurred"
              src="../../../static/images/shared/facebook.png"
              objectFit="contain"
              quality={11}
              alt="Facebook"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/upskillist/"
          >
            <StaticImage
              placeholder="blurred"
              src="../../../static/images/shared/instagram.png"
              objectFit="contain"
              quality={19}
              alt="Instagram"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/upskillist/"
          >
            <StaticImage
              placeholder="blurred"
              src="../../../static/images/shared/linked-in.png"
              objectFit="contain"
              quality={19}
              alt="LinkedIn"
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/upskillist/"
          >
            <StaticImage
              placeholder="blurred"
              src="../../../static/images/shared/twitter.png"
              objectFit="contain"
              quality={21}
              alt="Twitter"
            />
          </a>
        </div>
        <div>
          <a
            href="https://www.upskillist.pro/terms/"
            target="_blank"
            rel="noreferrer"
          >
            Terms
          </a>
          <a
            href="https://www.upskillist.pro/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            href="https://www.upskillist.pro/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            Cookie Policy
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

import React from 'react';
import Footer from '../components/Shared/Footer';
import SignupHeader from '../components/Auth/Shared/SignupHeader';
import WorkspacesView from '../components/Shared/WorkspacesView';

const Workspaces:React.FC = () => (
  <>
    <SignupHeader />
    <WorkspacesView />
    <Footer />
  </>
);

export default Workspaces;

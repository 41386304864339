import { message } from 'antd';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Role } from '../../models';
import { getHeaders, postEvent } from '../../services/utils';
import * as styles from '../../styles/Workspaces.module.scss';
import { InviteDetails } from '../../types/inviteDetails';
import { getEnterpriseProfile } from '../GraphQL/Services';

type Props = {
  invitation: InviteDetails;
  logo: string;
  enterpriseDetails: any;
  updateUserAttributes: (workspaceId: string, role: Role[] | 'LEARNER') => Promise<void>;
  setIsLoading: React.Dispatch<React.SetStateAction<{
    loading: boolean;
    loadingText: string;
  }>>,
  currentAuthUser: any;
  isFirstProfile: boolean;
}

const PendingInvitations: React.FC<Props> = ({
  invitation,
  logo,
  enterpriseDetails,
  updateUserAttributes,
  setIsLoading,
  currentAuthUser,
  isFirstProfile,
}) => {
  const [ctaLoading, setCtaLoading] = useState(false);
  const [joined, setJoined] = useState(false);
  const [userIdForAcceptedInvitation, setUserIdForAcceptedInvitation] = useState('');

  const acceptInvitationHandler = async () => {
    setCtaLoading(true);

    try {
      const patchApiName = 'enterpriseappinviteuser';
      const pathName = `/invite?id=${invitation.id}`;
      const groupId = invitation?.groupID;
      const headers = await getHeaders();
      const response = await API.put(patchApiName, pathName, {
        headers,
        body: {
          status: 'ACCEPTED',
          sub: currentAuthUser.attributes.sub,
          ...(groupId && { group_id: groupId }),
        },
      });
      setUserIdForAcceptedInvitation(response.user.id);
      setJoined(true);
      const enterpriseId = invitation?.inviterEnterpriseID;
      const userId = response.user.id;
      const companyProfileInfo = await getEnterpriseProfile(enterpriseId);
      const enterpriseName = companyProfileInfo?.name;
      const payload = {
        userId,
        enterpriseId,
        originalTimestamp: dayjs().toISOString(),
        sentAt: dayjs().toISOString(),
      };
      postEvent({
        ...payload,
        event: 'Invite Accepted',
        properties: {
          invitationId: invitation?.id,
          inviterId: invitation?.inviterID,
          isFirstProfile,
          accountName: enterpriseName,
        },
      });
      postEvent({
        ...payload,
        event: 'Signed Up',
        properties: {
          type: 'INVITED',
          accountName: enterpriseName,
        },
      });
      postEvent({
        ...payload,
        event: 'Account Added User',
        properties: {
          role: response?.user?.roles?.[0] || 'LEARNER',
          accountName: enterpriseName,
        },
      });
    } catch (error) {
      message.error('Something went wrong');
    }

    setCtaLoading(false);
  };

  const navigateToJoinedEnterprise = async () => {
    setIsLoading({ loadingText: 'Loading...', loading: true });
    await updateUserAttributes(userIdForAcceptedInvitation, 'LEARNER');
  };

  return (
    <div className={styles.workspace}>
      {logo ? <img className={styles.enterpriseLogo} src={logo} alt="logo" /> : <div className={styles.fallbackIcon} />}
      <h2>{enterpriseDetails.name}</h2>
      {
        !joined
          ? (
            <button
              className={`${styles.toggleCta} ${styles.singleCtaMargin} ${styles.joinCta}`}
              type="button"
              onClick={acceptInvitationHandler}
              disabled={ctaLoading}
            >
              Join
            </button>
          )
          : (
            <button
              className={`${styles.toggleCta} ${styles.singleCtaMargin} ${styles.continueCta}`}
              type="button"
              disabled={ctaLoading}
              onClick={navigateToJoinedEnterprise}
            >
              Continue
            </button>
          )
      }

    </div>
  );
};

export default PendingInvitations;

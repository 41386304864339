import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import brandLogo from '../../../../static/images/learner/certificate/brand-image.png';
import * as styles from '../../../styles/SignupHeader.module.scss';

const SignupHeader = () => (
  <>
    <Helmet>
      <link rel="canonical" href="https://www.upskillist.pro/" />
    </Helmet>
    <header className={styles.headerContainer}>
      <div className={styles.header}>
        <div className={styles.brandLogo}>
          <Link to="https://www.upskillist.pro/">
            <img alt="Upskillist" src={brandLogo} />
            <div className={styles.pro}>Pro</div>
          </Link>
        </div>
      </div>
    </header>
  </>
);

export default SignupHeader;

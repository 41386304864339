/* eslint-disable import/prefer-default-export */
import { API } from 'aws-amplify';
import { InviteDetails } from '../types/inviteDetails';
import { getHeaders } from './utils';

const getPendingInvitations = async (currentUser: any) => {
  const apiName = 'enterpriseappinviteuser';
  const pathName = `/invite?email=${encodeURIComponent(currentUser.attributes.email)}`;
  const headers = await getHeaders();
  const listInvitations = await API.get(apiName, pathName, {
    headers,
  });
  const pendingInvitations = listInvitations?.items?.filter((invitation: InviteDetails) => {
    if (invitation.inviteeID) {
      return false;
    }
    if (invitation.status === 'ACCEPTED') {
      return false;
    }
    return invitation;
  });
  return pendingInvitations;
};

export {
  getPendingInvitations,
};

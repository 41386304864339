// extracted by mini-css-extract-plugin
export var workspaceWrapper = "Workspaces-module--workspaceWrapper--39t_7";
export var cardContainer = "Workspaces-module--cardContainer--PNTyx";
export var cardHeading = "Workspaces-module--cardHeading--1XMNU";
export var yourWorkspaces = "Workspaces-module--yourWorkspaces--1TA7-";
export var workspace = "Workspaces-module--workspace--150aS";
export var emailText = "Workspaces-module--emailText--37olG";
export var fallbackIcon = "Workspaces-module--fallbackIcon--1hwok";
export var enterpriseLogo = "Workspaces-module--enterpriseLogo--o5oux";
export var toggleCta = "Workspaces-module--toggleCta--z8Fyd";
export var joinCta = "Workspaces-module--joinCta--1Sab3";
export var continueCta = "Workspaces-module--continueCta--1WYMI";
export var singleCtaMargin = "Workspaces-module--singleCtaMargin--2gDYw";
export var createWorkspace = "Workspaces-module--createWorkspace--X1N-x";
export var text = "Workspaces-module--text--11IdX";
export var createWorkspaceCta = "Workspaces-module--createWorkspaceCta--TfFk0";